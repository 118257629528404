import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import StructuredData from '../components/structured-data'

function ContactPage() {
  return (
    <Layout>
      <SEO title="Contact Us" slug="/contact" />
      <StructuredData pageType="webPage" slug="contact" pageTitle="Contact" />
      <div className="container pb-10 lg:pb-20">
        <h1 className="text-3xl lg:text-5xl mb-6 lg:mb-10">Contact Us</h1>
        <p className="mb-10">
          If you have any questions or need help, feel free to fill out the form
          below or call us at{' '}
          <span className="font-semibold">888-580-7627</span>.
        </p>
        <form
          className="max-w-xl"
          data-netlify="true"
          name="contact"
          netlify-honeypot="bot-field"
          method="POST"
        >
          <div aria-hidden="true" className="hidden">
            <label>
              Don’t fill this out if you&apost;re human:{' '}
              <input name="bot-field" />
            </label>
          </div>
          <label className="block">
            <span className="text-gray-700">Name</span>
            <input
              className="form-input mt-1 block w-full"
              name="name"
              placeholder="Mary Smith"
            />
          </label>
          <label className="block mt-6">
            <span className="text-gray-700">Email</span>
            <input
              className="form-input mt-1 block w-full"
              name="email"
              type="email"
              placeholder="john@example.com"
            />
          </label>
          <label className="block mt-6">
            <span className="text-gray-700">Message</span>
            <textarea
              className="form-textarea mt-1 block w-full"
              name="message"
              placeholder="How can we help you?"
              rows="5"
            ></textarea>
          </label>
          <button className="btn btn-teal mt-10" type="submit">
            Contact Us
          </button>
          <input type="hidden" name="form-name" value="contact" />
        </form>
      </div>
    </Layout>
  )
}

export default ContactPage
